<template>
  <div
    v-tooltip="tooltipConfig"
    class="br-card"
    :class="[{ disabled: disabled, hover: hover, 'h-fixed': hFixed }]"
    :disabled="disabled"
    :aria-disabled="disabled"
  >
    <div v-if="showHeader" class="card-header">
      <slot name="header"></slot>
    </div>
    <div v-if="showContent" class="card-content">
      <slot name="content"></slot>
    </div>
    <div v-if="showFooter" class="card-footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue"
import TooltipMixin from "../../mixins/TooltipMixin"

// Props
defineProps({
  /**
   * Habilita o estado hover ao card
   */
  hover: {
    type: Boolean,
    default: null,
  },
  /**
   * Habilita altura fixa e ativa barra de rolagem se necessário
   */
  hFixed: {
    type: Boolean,
    default: false,
  },
  /**
   * Aplica estilo desabilitado ao componente
   */
  disabled: {
    type: Boolean,
    default: null,
  },
})

const tooltipConfig = ref({
  text: TooltipMixin.tooltipText,
  place: TooltipMixin.tooltipPlace,
  type: TooltipMixin.tooltipType,
  timer: TooltipMixin.tooltipTimer,
})

const showHeader = ref(true)
const showContent = ref(true)
const showFooter = ref(true)

onMounted(() => {
  const rootElement = document.querySelector("br-card")

  // Check if header slot is empty
  const headerSlot = rootElement?.shadowRoot?.querySelector(".card-header slot")
  showHeader.value = headerSlot && headerSlot?.assignedElements()?.length > 0

  // Check if footer slot is empty
  const footerSlot = rootElement?.shadowRoot?.querySelector(".card-footer slot")
  showFooter.value = footerSlot && footerSlot?.assignedElements()?.length > 0
})
</script>

<script>
export default {
  name: "BrCard",
}
</script>

<style lang="scss">
@import "~@govbr-ds/core/src/partial/scss/_base";
@import "~@govbr-ds/core/src/partial/scss/utilities/_states";
@import "~@govbr-ds/core/src/components/card/_card";
</style>
