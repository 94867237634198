import { convertToBoolean } from '../util/Utils'

const Checkgroup = {
  props: {
    /** **[OPCIONAL]** Declara o comportamento checkgroup e coloca o checkbox como sendo filho. O pai possui o mesmo valor para a propriedade 'checkgroup-parent' */
    checkgroupChild: {
      type: String,
      default: null,
    },
    /** **[OPCIONAL]** Declara o comportamento checkgroup e coloca o checkbox como sendo pai. O filho possui o mesmo valor para a propriedade 'checkgroup-child' */
    checkgroupParent: {
      type: String,
      default: null,
    },
    /** **[OPCIONAL]** Estado indeterminate do checkbox. */
    indeterminate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      children: [], // Array que armazena objetos contendo dados do checkbox filho.
      isIndeterminate: convertToBoolean(this.indeterminate), // Estado indeterminate do checkbox.
      reverseFlag: false, // Flag usada para controlar a mudança de estado do checkbox filho no modo reverso (filho pra pai).
      syncCheckedState: convertToBoolean(this.checked),
    }
  },
  watch: {
    checked(newValue) {
      this.isChecked = convertToBoolean(newValue)
      if (this.checkgroupParent) {
        this.fireParentChangeStateEvent()
      }
      if (this.checkgroupChild) {
        this.fireChildChangeStateEvent()
      }
    },
    isIndeterminate() {
      this.$refs['input-checkbox'].indeterminate = this.isIndeterminate
      if (this.checkgroupParent && this.checkgroupChild) {
        this.fireChildChangeStateEvent()
      }
    },
    isChecked() {
      if (this.checkgroupParent && this.checkgroupChild) {
        this.fireChildChangeStateEvent()
      }
    },
  },
  methods: {
    /**
     * Dispara o evento "<nome-do-grupo>-child-change" registrado no event-bus.
     * Esse evento é disparado pelo checkbox filho.
     * É passado como payload um objeto contendo dados sobro o checkbox filho.
     */
    fireChildChangeStateEvent() {
      if (!this.reverseFlag) {
        window.eventBus.fire(`${this.checkgroupChild}-child-change`, {
          id: this._id,
          isChecked: this.isChecked,
          isIndeterminate: this.isIndeterminate,
        })
      }
    },

    /**
     * Dispara o evento "<nome-do-grupo>-parent-change" registardo no event-bus.
     * Esse método também registra os estados dos checkboxes filhos na lista de checkboxes filhos do pai.
     * Esse evento é disparado pelo checkbox pai.
     * É passado como payload um objeto contendo o estado do checkbox pai.
     */
    fireParentChangeStateEvent() {
      window.eventBus.fire(`${this.checkgroupParent}-parent-change`, {
        parentState: this.isChecked,
      })
      this.children.forEach((child) => {
        this.trackChildState({
          id: child.id,
          isChecked: this.isChecked,
          isIndeterminate: false,
        })
      })
    },

    /**
     * Dispara o evento <nome-do-grupo>-request-checked registrado no event-bus.
     * Esse evento é disparado pelo checkbox filho.
     */
    fireRequestParentCheckedStateEvent() {
      window.eventBus.fire(`${this.checkgroupChild}-request-checked`)
    },

    /**
     * Dispara o evento <nome-do-grupo>-response-checked registrado no event-bus.
     * Esse evento é disparado pelo checkbox pai.
     * É passado como payload o valor do atributo checked.
     */
    fireResponseChildCheckedStateEvent() {
      window.eventBus.fire(`${this.checkgroupParent}-response-checked`, this.syncCheckedState)
    },

    /**
     * Dispara o evento click no input.
     * Esse disparo ocorre no sincronismo reverso do checkgroup, ou seja de filho para pai.
     * Para isso é usado a flag reverseFlag
     */
    fireReverseClick() {
      this.reverseFlag = true
      this.$el.querySelector('input').click()
      this.reverseFlag = false
    },

    /**
     * Dispara o evento "<nome-do-grupo>-store-child" registrado no event-bus.
     * Esse evento é disparado pelo checkbox filho.
     * É passado como payload um objeto contento dados sobre o checkbox filho.
     */
    fireStoreChildEvent() {
      window.eventBus.fire(`${this.checkgroupChild}-store-child`, {
        id: this._id,
        isChecked: this.isChecked,
        isIndeterminate: this.isIndeterminate,
      })
    },

    /**
     * Determina o estado de todos os checkboxes filhos como um todo.
     * @returns {Boolean, String} Estado de todos os checkboxes filhos. Pode ser true, false ou indeterminate
     */
    getAllChildState() {
      if (this.isAnyChildIndeterminate()) {
        return 'indeterminate'
      } else if (this.isAllChildTrue()) {
        return true
      } else if (this.isAllChildFalse()) {
        return false
      } else {
        return 'indeterminate'
      }
    },

    /**
     * Recebe o estado do checkbox pai.
     * Método usado pelo checkbox filho.
     * @param {Object} event Objeto Event. Contém o dado enviado na propriedade detail
     */
    getParentCheckedState(event) {
      this.isChecked = event.detail || this.isChecked
      this.syncCheckedState = event.detail && this.checkgroupParent ? true : this.syncCheckedState
    },

    /**
     * Verifica se todos os checkboxes filhos estão no estado false.
     * @returns {Boolean} Estado dos checkboxes filhos.
     */
    isAllChildFalse() {
      return this.children.filter((child) => child.isChecked === false).length === this.children.length
    },

    /**
     * Verifica se todos os checkboxes filhos estão no estado true.
     * @returns {Boolean} Estado dos checkboxes filhos.
     */
    isAllChildTrue() {
      return this.children.filter((child) => child.isChecked === true).length === this.children.length
    },

    /**
     * Verifica se algum checkbox filho está no estado indeterminate.
     * Para o caso de um checkbox filho ser também um checkbox pai de outro grupo.
     * @returns {Boolean} Estado dos checkboxes filhos em relação ao estado indeterminate
     */
    isAnyChildIndeterminate() {
      return this.children.filter((child) => child.isIndeterminate === true).length > 0
    },

    /**
     * Registra o evento "<nome-do-evento>-child-change" no event-bus com o método de callback "updateParentState"
     * Esse evento serve para monitorar a mudança de estado do checkbox filho.
     * Esse evento é registrado pelo checkbox pai.
     */
    registerChildChangeStateEvent() {
      window.eventBus.register(`${this.checkgroupParent}-child-change`, this.updateParentState)
    },

    /**
     * Registra o evento "<nome-do-grupo>-parent-change" no event-bus com o método de callback "updateChildState".
     * Esse evento serve para monitorar a mudança de estado do checkbox pai.
     * Esse evento é registrado pelo checkbox filho.
     */
    registerParentChangeStateEvent() {
      window.eventBus.register(`${this.checkgroupChild}-parent-change`, this.updateChildState)
    },

    /**
     * Registra o evento <nome-do-grupo>-request-checked> no event-bus com o método de callback "sendParentCheckedState".
     * Esse evento serve para o checkbox filho pedir o estado do checkbox pai
     * Esse evento é registrado pelo checkbox pai.
     */
    registerRequestParentCheckedStateEvent() {
      window.eventBus.register(`${this.checkgroupParent}-request-checked`, this.fireResponseChildCheckedStateEvent)
    },

    /**
     * Registra o evento <nome-do-grupo>-response-checked> no event-bus com o método de callback "getParentCheckedState".
     * Esse evento serve para o checkbox pai envie o seu estado do checkbox filho
     * Esse evento é registrado pelo checkbox filho.
     */
    registerResponseChildCheckedStateEvent() {
      window.eventBus.register(`${this.checkgroupChild}-response-checked`, this.getParentCheckedState)
    },

    /**
     * Registra o evento <nome-do-grupo>-store-child> no event-bus com o método de callback "storeChild".
     * Esse evento serve para armazenar os estados dos checkboxes filho no checkbox pai.
     * Esse evento é registrado pelo checkbox pai.
     */
    registerStoreChildEvent() {
      window.eventBus.register(`${this.checkgroupParent}-store-child`, this.storeChild)
    },

    /**
     * Controla a configuração do event-bus
     */
    setEventBus() {
      if (this.checkgroupChild) {
        this.registerParentChangeStateEvent()
        this.registerResponseChildCheckedStateEvent()
      }
      if (this.checkgroupParent) {
        this.registerStoreChildEvent()
        this.registerChildChangeStateEvent()
        this.registerRequestParentCheckedStateEvent()
      }
    },

    /**
     * Verifica se o estado do checkbox pai é indeterminado no momento do click.
     * Se for indeterminado, o estado do checkbox passa a ser true para checked e false para indeterminate
     */
    setParentIndeterminateStateOnClick() {
      if (this.isIndeterminate) {
        this.setState({ isChecked: true, isIndeterminate: false })
      }
    },

    /**
     * Configura os estados checked e indeterminate do checkbox.
     * @param {Boolean} isChecked Estado checked do checkbox
     * @param {Boolean} isIndeterminate Estado indeterminate do checkbox
     */
    setState({ isChecked, isIndeterminate }) {
      this.isIndeterminate = isIndeterminate
      this.isChecked = isChecked
    },

    /**
     * Registra os handlers disparados na ocorrência do evento "change".
     * Esses handlers são disparados pela acão direta do usuário ao clicar no checkbox.
     * O evento "change" é disparado automaticamente pelo browser após o evento "click".
     */
    setTriggers() {
      if (this.checkgroupParent) {
        this.$el.addEventListener('change', this.fireParentChangeStateEvent)
      }
      if (this.checkgroupChild) {
        this.$el.addEventListener('change', this.fireChildChangeStateEvent)
      }
    },

    /**
     * Sincroniza o estado inicial do checkgroup
     */
    synchronizeCheckgroup() {
      if (this.checkgroupChild) {
        this.fireRequestParentCheckedStateEvent()
        this.fireChildChangeStateEvent()
      }
    },

    /**
     * Armazena os dados do checkbox filho.
     * Handler para o evento "<nome-do-grupo>-store-child".
     * @param {Object} event Objeto Event. Contém os dados enviados na propriedade "detail"
     */
    storeChild(event) {
      if (this.children.filter((child) => child.id === event.detail.id).length === 0) {
        this.children.push(event.detail)
      }
    },

    /**
     * Rastreia alterações no checkbox filho, armazenando os seus dados no checkbox pai.
     * @param {Object} child Objeto contento dados sobre o checkbox filho.
     */
    trackChildState(child) {
      const index = this.children.findIndex((_child) => _child.id === child.id)
      this.children[index].isChecked = child.isChecked
      this.children[index].isIndeterminate = child.isIndeterminate
    },

    /**
     * Atualiza o estado do checkbox filho.
     * Handler para o evento "<nome-do-grupo>-parent-change".
     * @param {Object} event Objeto Event. Contém o dado enviado na propriedade "detail"
     */
    updateChildState(event) {
      if (this.isIndeterminate || this.isChecked !== event.detail.parentState) {
        this.fireReverseClick()
      }
    },

    /**
     * Atualiza o estado do checkbox pai.
     * Handler para o evento "<nome-do-grupo>-child-change".
     * @param {Object} event Objeto Event. Contém os dados enviados na propriedade "detail".
     */
    updateParentState(event) {
      this.trackChildState(event.detail)
      switch (this.getAllChildState()) {
        case true:
          this.setState({ isChecked: true, isIndeterminate: false })
          break
        case false:
          this.setState({ isChecked: false, isIndeterminate: false })
          break
        case 'indeterminate':
          this.setState({ isChecked: false, isIndeterminate: true })
          break
      }
      this.emitOnChangeEvent()
      this.emitUpdateCheckedEvent()
    },
  },
  created() {
    this.setEventBus()
  },
  beforeMount() {
    if (this.checkgroupChild) {
      this.fireStoreChildEvent()
    }
  },
  mounted() {
    this.setTriggers()
    this.synchronizeCheckgroup()
  },
}

export default Checkgroup
