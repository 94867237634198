<template>
  <div
    v-tooltip="{
      text: tooltipText,
      place: tooltipPlace,
      type: tooltipType,
      timer: tooltipTimer,
    }"
    class="br-switch"
    :class="_classList"
  >
    <input
      v-bind="$attrs"
      :id="_id"
      ref="input-checkbox"
      :checked="isChecked"
      type="checkbox"
      :name="name"
      :value="_value"
      :aria-label="ariaLabel"
      :disabled="disabled"
      @change="onChangeEvent($event)"
      @click.stop
    />
    <label :for="_id">{{ label }}</label>
    <div
      v-if="labelChecked && labelNotChecked"
      class="switch-data"
      :data-enabled="labelChecked"
      :data-disabled="labelNotChecked"
    ></div>
  </div>
</template>

<script>
import Checkgroup from "../../mixins/checkgroup"
import TooltipMixin from "../../mixins/TooltipMixin"
import { convertToBoolean } from "../../util/Utils"

const Checkbox = {
  name: "brCheckbox",
  inheritAttrs: false,
  emits: [
    "onChange", // Evento emitido quando o estado do checkbox é modificado.
    "update:checked", // Evento emitido para fazer o two-way data binding com a prop checked.
    /**
     * Referência sobre o consumo de eventos:
     * [Eventos Emitidos pelos Componentes](/?path=/story/eventos-emitidos-pelos-componentes--page)
     */
  ],
  mixins: [Checkgroup, TooltipMixin],
  props: {
    /** **[OPCIONAL]** Acessibilidade: define uma cadeia de caracteres para descrever o elemento. */
    ariaLabel: {
      type: String,
      default: null,
    },
    /** **[OPCIONAL]** Se estiver presente, indica que o estado inicial do switch é true. */
    checked: {
      type: [String, Boolean],
      default: false,
    },
    /** **[OPCIONAL]** Se estiver presente, indica que o switch está desabilitado. */
    disabled: {
      type: Boolean,
      default: false,
    },
    /** **[OPCIONAL]** Se estiver presente, coloca um ícone na chave de seleção para indicar a mudança de estado. */
    icon: {
      type: Boolean,
      default: false,
    },
    /** **[OPCIONAL]** Id do componente. Usado, também para conectar label correspondente. */
    id: {
      type: String,
      default: null,
    },
    /** **[OPCINAL]** Indica o tamanho do switch. */
    size: {
      type: String,
      validator: function (value) {
        return [null, "large", "small"].includes(value)
      },
      default: null,
    },
    /** **[OPCIONAL]** Descreve o que o switch faz quando a alternância estiver ativada. Se estiver presente sobrescre a label passada por slot. */
    label: {
      type: String,
      default: null,
    },
    /** **[OPCIONAL]** Rótulo para a chave ativada, que complementa a label, auxiliando no entendimento da posição em que a chave se encontra */
    labelChecked: {
      type: String,
      default: null,
    },
    /** **[OPCIONAL]** Rótulo para a chave desativada, que complementa a label, auxiliando no entendimento da posição em que a chave se encontra */
    labelNotChecked: {
      type: String,
      default: null,
    },
    /** **[OPCIONAL]** Define o name que será atribuido ao checkbox. */
    name: {
      type: String,
      default: null,
    },
    /** O valor da caixa de seleção. */
    value: {
      type: String,
      default: null,
    },
    /** **[OPCIONAL]** Label localizado acima do switch */
    top: {
      type: Boolean,
      default: false,
    },
    /** **[OPCIONAL]** Label localizado a direita do switch */
    right: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isChecked: convertToBoolean(this.checked),
    }
  },
  computed: {
    /**
     * [PROPRIEDADE COMPUTADA] - Lista de classes.
     * @return {Object} Objeto contendo classes aplicadas na raiz do componente.
     */
    _classList() {
      return {
        icon: this.icon,
        top: this.top,
        right: this.right,
        large: this.size === "large",
        small: this.size === "small",
      }
    },
    /**
     * [PROPRIEDADE COMPUTADA] Computa qual id será usado no checkbox.
     * @return {String} Valor da prop id se houver, senão valor computado usando o uid.
     */
    _id() {
      return this.id || "br-checkbox-" + this._.uid
    },
    /**
     * [PROPRIEDADE COMPUTADA] Computa o valor usado no atributo value do checkbox.
     * @return {String, Boolean} Valor da prop value se houver, senão valor do data isChecked.
     */
    _value() {
      return this.value || this.isChecked
    },
  },
  methods: {
    /**
     * Emite evento customizado com o nome "onChange"
     */
    emitOnChangeEvent() {
      this.$emit("onChange", this.$el.querySelector("input"))
    },

    /**
     * Emite o evento "update:checked", passando o estado "checked" do checkbox (true ou false).
     * Use o evento para fazer o two-way data binding com a prop "checked" ao usar o componente.
     */
    emitUpdateCheckedEvent() {
      this.$emit("update:checked", this.isChecked)
    },

    /**
     * Handler para o evento "change"
     */
    onChangeEvent(event) {
      this.updateCheckboxState(event)
      this.emitOnChangeEvent()
      this.emitUpdateCheckedEvent()
    },

    updateCheckboxState(event) {
      this.isChecked = event.target.checked
      this.isIndeterminate = event.target.indeterminate
    },
  },
}

export default Checkbox
</script>

<style lang="scss">
@import "~@govbr-ds/core/src/partial/scss/_base";
@import "~@govbr-ds/core/src/partial/scss/_utilities";
@import "~@govbr-ds/core/src/components/switch/_switch";
</style>
