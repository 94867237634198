<template>
  <div class="br-checkbox" :class="_classList">
    <input
      v-bind="$attrs"
      :id="_id"
      ref="input-checkbox"
      :checked="isChecked"
      type="checkbox"
      :name="name"
      :value="_value"
      :aria-label="ariaLabel"
      :disabled="disabled"
      @change="onChangeEvent($event)"
      @click.stop
    />
    <label
      v-tooltip="{
        text: tooltipText,
        place: tooltipPlace,
        type: tooltipType,
        timer: tooltipTimer,
      }"
      :for="_id"
      >{{ label }}</label
    >
    <slot></slot>
  </div>
</template>

<script>
import Checkgroup from "../../mixins/checkgroup"
import TooltipMixin from "../../mixins/TooltipMixin"
import { convertToBoolean } from "../../util/Utils"

const Checkbox = {
  name: "brCheckbox",
  inheritAttrs: false,
  emits: [
    "onChange", // Evento emitido quando o estado do checkbox é modificado.
    "update:checked", // Evento emitido para fazer o two-way data binding com a prop checked.
    /**
     * Referência sobre o consumo de eventos:
     * [Eventos Emitidos pelos Componentes](/?path=/story/eventos-emitidos-pelos-componentes--page)
     */
  ],
  mixins: [Checkgroup, TooltipMixin],
  props: {
    /** **[OPCIONAL]** Acessibilidade: define uma cadeia de caracteres para descrever o elemento. */
    ariaLabel: {
      type: String,
      default: null,
    },
    /** **[OPCIONAL]** Estado checked do checkbox. */
    checked: {
      type: [String, Boolean],
      default: false,
    },
    /** **[OPCIONAL]** Desabilita o checkbox. */
    disabled: {
      type: Boolean,
      default: false,
    },
    /** **[OPCIONAL]** Formata o checkbox para valido ou invalido. */
    format: {
      type: String,
      validator: function (value) {
        return ["invalid", "valid"].includes(value)
      },
      default: null,
    },
    /** **[OPCIONAL]** Id do componente. Usado, também para conectar label correspondente. */
    id: {
      type: String,
      default: null,
    },
    /** **[OPCIONAL]** Formata o componente para versão horizontal. */
    inline: {
      type: Boolean,
      default: false,
    },
    /** **[OPCIONAL]** Texto descritivo, localizado sempre à direita da caixa de opção.*/
    label: {
      type: String,
      default: null,
    },
    /** **[OPCIONAL]** Define o name que será atribuido ao checkbox. */
    name: {
      type: String,
      default: null,
    },
    /** O valor da caixa de seleção. */
    value: {
      type: String,
      default: null,
    },
    /** **[OPCIONAL]** Define se o label do checkbox deve ser escondido. */
    hiddenLabel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isChecked: convertToBoolean(this.checked),
    }
  },
  computed: {
    /**
     * [PROPRIEDADE COMPUTADA] - Lista de classes.
     * @return {Object} Objeto contendo classes aplicadas na raiz do componente.
     */
    _classList() {
      return {
        invalid: this.format == "invalid",
        valid: this.format == "valid",
        "d-inline": this.inline,
        disabled: this.disabled,
        "hidden-label": this.hiddenLabel,
      }
    },
    /**
     * [PROPRIEDADE COMPUTADA] Computa qual id será usado no checkbox.
     * @return {String} Valor da prop id se houver, senão valor computado usando o uid.
     */
    _id() {
      return this.id || "br-checkbox-" + this._.uid
    },
    /**
     * [PROPRIEDADE COMPUTADA] Computa o valor usado no atributo value do checkbox.
     * @return {String, Boolean} Valor da prop value se houver, senão valor do data isChecked.
     */
    _value() {
      return this.value || this.isChecked
    },
  },
  methods: {
    /**
     * Emite evento customizado com o nome "onChange"
     */
    emitOnChangeEvent() {
      this.$emit("onChange", this.$el.querySelector("input"))
    },

    /**
     * Emite o evento "update:checked", passando o estado "checked" do checkbox (true ou false).
     * Use o evento para fazer o two-way data binding com a prop "checked" ao usar o componente.
     */
    emitUpdateCheckedEvent() {
      this.$emit("update:checked", this.isChecked)
    },

    /**
     * Handler para o evento "change"
     */
    onChangeEvent(event) {
      this.updateCheckboxState(event)
      this.emitOnChangeEvent()
      this.emitUpdateCheckedEvent()
    },

    updateCheckboxState(event) {
      this.isChecked = event.target.checked
      this.isIndeterminate = event.target.indeterminate
    },
  },
}

export default Checkbox
</script>

<style lang="scss">
@import "~@govbr-ds/core/src/partial/scss/_base";
@import "~@govbr-ds/core/src/partial/scss/_utilities";
@import "~@govbr-ds/core/src/components/checkbox/_checkbox";
@import "~@govbr-ds/core/src/components/input/_input";

.br-checkbox {
  input {
    &:indeterminate {
      + label {
        &::after {
          border: solid var(--selected);
          border-color: var(--background-light);
          border-width: 0 0 3px;
          top: 2px;
          transform: none;
          content: "";
          height: var(--icon-size-sm);
          left: 8px;
          position: absolute;
          width: 8px;
        }
        &::before {
          --interactive-rgb: var(--color-rgb);
          background: var(--selected);
          border-color: var(--selected);
          border: 1px solid var(--border-color);
          border-radius: 4px;
          content: "";
          height: var(--checkbox-size);
          left: 0;
          position: absolute;
          width: var(--checkbox-size);
        }
      }
    }
  }
}
</style>
